/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import 'ngx-toastr/toastr';

* {
  font-family: Roboto,Helvetica Neue,sans-serif;
}

// Custom styles
.td-primary-b-border {
  border-bottom: 1px solid #dadce0;
}
.td-primary-r-border {
  border-right: 1px solid #dadce0;
}
.td-second-b-border {
  border-bottom: 2px solid #dadce0;
}

.dashed-b-border {
  border-bottom: 1px solid #dadce0;
}

.bg-tableLighter {
  background-color: #f8f9fa;
}

input[type="date"],
select {
  background-color: transparent;
}

.text-white {
  color: #fff !important;
}

table {
  color: #202124;
}
// 表頭文字
.theader-text {
    -webkit-text-decoration-line: underline;
    -moz-text-decoration-line: underline;
    text-decoration-line: underline;
    -webkit-text-decoration-style: dashed;
    -moz-text-decoration-style: dashed;
    text-decoration-style: dashed;
    -webkit-text-decoration-color: #80868b;
    -moz-text-decoration-color: #80868b;
    text-decoration-color: #80868b;
    text-underline-position: under;
    text-underline-offset: 2px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: .25px;
    color: #5f6368;
    white-space: pre-line;
}


// 表格滑動條設定
.scroll-container {
  position: relative;

}

.table-wrapper {
  // max-height: 1000px;
  overflow-y: auto;
  position: relative;
}

.table-dynamic {
  border-collapse: collapse;
  width: 100%;
}

.table-dynamic.overflowing {
  width: max-content;
}

thead th {
  position: sticky;
  top: 0;
  // background-color: #DEEAF6 !important;
  z-index: 2;
}

tr, td {
  line-height: 20px;
}

// input checkbox

.checkbox-container {
  position: relative;
  display: inline-block;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 4px;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}



// ngx-toastr
.toast-error{
  background-color: #E04556 !important;
  color: #fff !important;
  font-size: .9rem !important;
  border-radius: 10px !important;
  padding: .5rem 3rem!important;
  top: 1rem !important;
  left: 0 !important;
  right: 0 !important;
  width: calc(100% - 2rem) !important;
}
.toast-success{
  background-color: #5E8F87 !important;
  color: #fff !important;
  font-size: .9rem !important;
  border-radius: 10px !important;
  padding: .5rem 3rem!important;
  top: 1rem !important;
  left: 0 !important;
  right: 0 !important;
  width: calc(100% - 2rem) !important;
}

.table-dynamic th, .table-dynamic td {
  width: var(--dynamic-col-width);
}

// loading spinner
.loading-container {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.loading-img {
  opacity: 0;
  animation: fadeIn 1.5s infinite;
}

/* 動畫關鍵幀 */
@keyframes fadeIn {
  0%, 100% { opacity: 0; }
  8%, 25% { opacity: 1; }
}

.loading-img:nth-child(1) {
  animation-delay: 0s;
}

.loading-img:nth-child(2) {
  animation-delay: .5s;
}

.loading-img:nth-child(3) {
  animation-delay: 1s;
}


// 全域滾動條樣式
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(209, 209, 209);
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(204, 204, 204);
}


thead.sticky {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: inherit;
  overflow-x: hidden;
}
